@import "../../../variables";

.formFieldWithHint {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 16px;

    .label {
        margin-bottom: 16px;

        span {
            font-weight: 400;
            font-family: 'Biotif', sans-serif;
        }

        p {
            font-size: 10px;
            font-weight: 500;
            opacity: 0.7;
            max-width: 150px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        flex-direction: column;
        align-items: unset;
        display: unset;

        .label p {
            max-width: unset;
        }
    }
}
