@import '../../../../variables';
@import "../clientVariables";

.clientStatement .content {
    $paddingStatement: 16px;
    padding: $paddingStatement 0;
    display: flex;
    justify-content: space-around;

    > * {
        width: 45%;
    }

    span {
        width: 100%;
    }

    .lstStatement {
        overflow-y: auto;
        min-width: 280px;

        max-height: calc(100vh - #{$heightOutsideTabClientContent} - #{$paddingStatement * 2});

        .title {
            font-family: "Biotif", sans-serif;
            font-weight: 500;
            font-size: 13px;
        }


        .transactionItem {
            padding: 8px 16px;
            display: flex;
            align-items: center;
            border-radius: var(--radius-default);
            margin-right: 8px;

            cursor: pointer;
            transition: all .2s;

            .icon {
                margin-right: 12px;
                $size: 24px;
                height: $size;
                width: $size;
                border-radius: 50%;
                border: 1px solid var(--color-font);

                img {
                    width: 16px;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    filter: invert(16%) sepia(36%) saturate(1032%) hue-rotate(201deg) brightness(94%) contrast(88%);

                    @media (prefers-color-scheme: dark) {
                        filter: invert(0.7);
                    }
                }
            }

            .info {
                span {
                    display: block;
                    white-space: nowrap;
                }

                :first-child {
                    font-family: "Biotif", sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                }

                :nth-child(2), :nth-child(3) {
                    font-size: 11px;
                    font-weight: 400;
                }

                &.hasSecondAmount {
                    :nth-child(2) {
                        color: var(--color-success);
                    }

                    :nth-child(3) {
                        color: var(--color-error);
                    }
                }
            }

            &.active {
                cursor: default;
                background-color: var(--color-primary-light);
            }

            &:hover:not(.active) {
                background-color: var(--color-icon-button-hover);
            }
        }
    }

    .detailWrapper .backButton {
        display: none;
        position: absolute;
        left: 8px;
        @media (prefers-color-scheme: dark) {
            filter: invert(1);
        }
    }

    &.notFound {
        justify-content: start;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .lstStatement {
            $padding: 16px;
            $paddingBottom: 16px;

            width: calc(100vw - #{$padding * 2});
            padding: 0 $padding $paddingBottom $padding;

            max-height: calc(100vh - #{$heightOutsideTabClientContentMobile} - #{$paddingStatement * 2} - #{$paddingBottom});

            .transactions .transactionItem.active {
                background: transparent;
                cursor: pointer;
            }
        }

        &.detailIsOpened {
            .detailWrapper {
                display: unset;
            }

            .lstStatement {
                display: none;
            }
        }

        .detailWrapper {
            display: none;
            width: unset;

            .backButton {
                display: unset;
            }
        }
    }
}
