.baseFormUser {
    .form {
        width: 300px;
        margin: 32px 0;

        .field {
            margin-bottom: 32px;
        }
    }
}
