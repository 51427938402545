.formPartner {
    .secretNewlyCreated {
        background-color: var(--color-background-warning);
        border-radius: var(--radius-default);

        display: flex;
        padding: 4px 4px 4px 16px;
        margin-bottom: 16px;
        justify-content: space-between;

        .label {
            display: flex;
            align-items: center;
        }
    }

    .form {
        margin: 32px 0;

        .field {
            max-width: 460px;
        }

        .formFee {
            display: flex;

            > * {
                margin-right: 16px;
            }

            .addIcon {
                filter: invert(1);
            }

            .field {
                max-width: 300px;
                align-items: center;

                .suffixField {
                    font-size: .8rem;
                    color: var(--color-selected);
                    font-weight: bold;
                    line-height: .8rem;
                    display: block;
                    margin-right: 8px;
                }
            }
        }
    }

    .actions {
        display: flex;
    }
}
