.partners {
    .tablePartners {
        table {
            width: 100%;

            tr:nth-child(even) td {
                background: var(--color-background-light);
            }

            tr {
                cursor: pointer;
            }
        }
    }
}
