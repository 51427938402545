.partnerBalance {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: end;
        width: 100%;
    }
}
