@import '../../../variables';

.withdrawDetail {
    .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 8px;
        max-width: 440px;

        > * {
            width: 50%;
            padding: 8px 0;

            &:last-child {
                width: 100%;
            }
        }
    }

    table {
        width: 100%;
        margin-bottom: 32px;

        td {
            width: 50%;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        table tbody tr {

            margin: 8px 0;

            &:first-child {
                margin-top: 16px;
            }

            &:last-child {
                margin-bottom: 16px;
            }
        }
    }
}
